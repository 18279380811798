<template lang="pug">
  v-dialog(
    v-model="openDialog"
    fullscreen
    eager
    transition="dialog-bottom-transition"
  )
    v-toolbar(
      color="purple"
      flat
      dark
    )
      span Pt Cash Voucher
      v-spacer
      v-btn(
        text
        small
        dense
        @click="openDialog = false"
      )
        v-icon mdi-close
    v-card
      v-overlay(
        :value="ptCashVoucherShowGetting"
        absolute
      )
        v-progress-circular(
          indeterminate
          size="60"
        )
      v-container(fluid)
        v-row
          v-col(cols="6")
            v-row
              v-col(cols="6")
                base-card(title="PT Cash NO." :text="ptCashVoucherShowData.pt_cash_voucher_number")
              v-col(cols="6")
                base-card(:title="approvedRejectedTitle" :text="approvedRejectedBy")
              v-col(cols="6")
                base-card(title="Address" :text="ptCashVoucherShowData.address")
              v-col(cols="6")
                base-card(title="Requested By" :text="ptCashVoucherShowData.created_by.name")
              v-col(cols="6")
                base-card(title="Pay To" :text="payTo")
              v-col(cols="2" v-if="!approvedRejectedBy")
                v-btn(
                  color="green"
                  dark
                  dense
                  small
                  block
                  @click="$emit('approve', ptCashVoucherId)"
                )
                  span Approve
              v-col(cols="2" v-if="!approvedRejectedBy")
                v-btn(
                  color="error"
                  dark
                  dense
                  small
                  block
                  @click="$emit('reject', ptCashVoucherId)"
                )
                  span Reject
          v-col(cols="6")
            v-simple-table
              template(v-slot:default)
                thead
                  tr
                    th Account Code
                    th Description
                    th.text-right Amount
                tbody
                  tr(v-for="item in ptCashVoucherShowData.details" :key="item.id")
                    th {{ item.account_code.account_code }}
                    td {{ item.description }}
                    th.text-right {{ item.amount.phpFormat() }}
                  tr
                    th(colspan="2") Total
                    th.text-right {{ totalAmount.phpFormat() }}
</template>
<script>
import ptCashVoucherRepository from '@/repositories/pt-cash-voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [ptCashVoucherShowVars, ptCashVoucherShowVarNames] = requestVars({ identifier: 'pt-cash-voucher-show', data: 'ptCashVoucherShowData' })

const ptCashVoucherShowHandler = new VueRequestHandler(null, ptCashVoucherShowVarNames)

function codeAndDescriptionTemplate (code, description) {
  return `<span class="primary--text mr-2">${code}</span><small class="blue-grey--text">${description}</small>`
}

export default {
  name: 'ShowPtCashVoucher',
  props: ['value', 'ptCashVoucherId'],
  data () {
    return {
      ...ptCashVoucherShowVars,
    }
  },
  created () {
    this.getPtCashVoucherById()
    this.initWebsockets()
  },
  computed: {
    openDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    totalAmount () {
      return this.ptCashVoucherShowData.details.reduce((t, c) => t + c.amount, 0)
    },
    approvedRejectedTitle () {
      const status = this.ptCashVoucherShowData.pt_cash_voucher_status_id
      if (status === 'RJ') return 'Rejected By'
      if (status === 'AP') return 'Approved By'
      return 'Awaiting Approval...'
    },
    approvedRejectedBy () {
      const approver = this.ptCashVoucherShowData.approved_rejected_by
      if (!approver) return null
      return approver.name
    },
    accountCode () {
      if (!this.ptCashVoucherShowData.account_code) return null
      const code = this.ptCashVoucherShowData.account_code.account_code
      const desc = this.ptCashVoucherShowData.account_code.description
      return codeAndDescriptionTemplate(code, desc)
    },
    payTo () {
      if (!this.ptCashVoucherShowData.pay_to) return null
      const code = this.ptCashVoucherShowData.pay_to.id
      const desc = this.ptCashVoucherShowData.pay_to.name
      return codeAndDescriptionTemplate(code, desc)
    },
  },
  watch: {
    ptCashVoucherId () {
      this.getPtCashVoucherById()
    },
  },
  methods: {
    initWebsockets () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBUpdateEvent', ({ model, data }) => {
          if (model === 'PtCashVoucher') {
            this.getPtCashVoucherById()
          }
        })
    },
    getPtCashVoucherById () {
      const handler = ptCashVoucherShowHandler
      const repository = ptCashVoucherRepository.show
      handler.setVue(this)
      handler.execute(repository, [this.ptCashVoucherId])
    },
  },
}
</script>